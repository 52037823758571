import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          Hi, I'm Zayne Nair, a junior at Wheeler High School. I'm a lifelong learner with a passion for applying knowledge to make a positive impact. I'm drawn to the intersection of science, math, and technology, and I'm particularly excited about software development, artificial intelligence, and data science. I'm eager to delve deeper into the theoretical underpinnings of computer science while also gaining practical experience through hands-on projects.
            <br/>
            <br/>

          When I am not working, I love to:

          </p>
          
          <ul>
            <li className="about-activity">
              <ImPointRight /> Play Tennis
            </li>
            <li className="about-activity">
              <ImPointRight /> Play Music
            </li>
            <li className="about-activity">
              <ImPointRight /> Volunteer
            </li>
          </ul>

        
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
