import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="Line Tracker Robot [GT Robotics]"
              description="The robot that I built was able to do numerous functions, including follow a line, scan and recognize a barcode, and detect magnets on the ground. In this project, I learned how to utilize motors and AI sensors. "
              ghLink="https://github.com/ZanyeNair/GTRSI-Code"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="Shared Chapters"
              description="SharedChapters is a site where you can create personalized e-books, share them with friends and family, and collaborate together on different thoughts. As part of this project, I am incharge of the creation of the admin portal, which includes the UI, API, and databases"
              ghLink="https://gitlab.com/kantan1"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="Bugsprint"
              description="BugSprint is a web applicaition which allows to manage bugs allowing to assign and track the status of the bug. This project was created using React.js, Node.js, and MongoDB."
              
            />
          </Col>

         
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
